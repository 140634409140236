<template>
    <div class="container">
        <div class="row reg checkout_section">
            <div class="col-md-8 col-md-offset-2">
                <div class="col-md-12">
                    <h4 class="title">CREATE AN ACCOUNT</h4>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <ValidationObserver v-slot="{ invalid }">
                            <form @submit.prevent="customerEnroll">
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label>Name</label>
                                        <ValidationProvider name="Name" rules="required|alpha" placeholder="Enter Name"
                                            v-slot="{ errors }">
                                            <input type="text" class="form-control" placeholder="Enter Full Name"
                                                v-model="user.name">
                                            <span>{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>Phone</label>
                                        <ValidationProvider name="Phone" rules="required|digits:11"
                                            placeholder="Enter Phone" v-slot="{ errors }">
                                            <input type="text" class="form-control" v-model="user.phone"
                                                placeholder="Enter Phone">
                                            <span>{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>Email address</label>
                                        <ValidationProvider name="E-mail" rules="required|email" v-slot="{ errors }">
                                            <input type="email" class="form-control" placeholder="Enter Email"
                                                v-model="user.email">
                                            <span>{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <div style="margin-top: 30px">
                                            <input type="checkbox" v-model="user.terms" id="exampleCheck1">
                                            <label for="exampleCheck1"> Sing Up for Newsletter</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label>Password</label>
                                        <ValidationProvider name="Password" rules="min:6|required|" vid="confirmation"
                                            v-slot="{ errors }">
                                            <input type="password" class="form-control" v-model="user.password"
                                                placeholder="Enter Password">
                                            <span>{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>Retype Password</label>
                                        <ValidationProvider name="Confirm Password"
                                            rules="required|confirmed:confirmation" v-slot="{ errors }">
                                            <input type="password" class="form-control"
                                                v-model="user.password_confirmation" placeholder="Retype Password">
                                            <span>{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-primary" :disabled="invalid">Sign Up</button>

                            </form>
                        </ValidationObserver>
                    </div>
                </div>
            </div>
        </div>
        <br>
    </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
export default {
    name: "registration",
    components: {
        ValidationObserver,
        ValidationProvider
    },
    data() {
        return {
            user: {
                name: "",
                email: "",
                phone: "",
                terms: "",
                password: "",
                passwordConfirm: "",
            },

            showError: false,
            resError: {
                email: [],
                phone: [],
            }
        };
    },
    watch: {
        'user.email'(oldvalue, newVal) {
            if (newVal) {
                this.resError.email = []
            }
        },
        'user.phone'(oldvalue, newVal) {
            if (newVal) {
                this.resError.phone = []
            }
        }
    },
    methods: {
        customerEnroll(e) {
            e.preventDefault();
            // // console.log(this.user);
            this.$store.dispatch("registration", this.user).then((res) => {
                // console.log('Data Registration = ', res);
                if (res === undefined) {
                    //this.$toasted.success('Successfully register.');
                    this.$router.push('/customer-dashboard').catch(() => { });
                    // console.log("Path================");
                    this.user = {
                        name: "",
                        email: "",
                        phone: "",
                        terms: "",
                        password: "",
                        passwordConfirm: "",
                    };
                } else {
                    //this.$toasted.error(res.message)
                    this.resError = res.errors;
                }
            });

        },
    },
};
</script>

<style>
.form-group.col-md-6 {
    text-align: left;
}

.row.reg {
    margin-top: 30px;
}

.checkout_section .title {
    border-bottom: 1px solid #464646;
    text-transform: uppercase;
    padding: 0 0 8px;
}
</style>
